import Airtable from 'airtable';

const baseTypeMap = {
  visual: process.env.GATSBY_AIRTABLE_VISUAL,
  music: process.env.GATSBY_AIRTABLE_MUSIC,
  technology: process.env.GATSBY_AIRTABLE_TECH,
}

export const uploadToDropbox = async (dbSDK, applicant, workSampleType, file, fields, type, setUploadError, applicantEmail) => {
  try {
    const dropbox_folder = process.env.GATSBY_DROPBOX_APP;
    const fileName = file.name.replace(/\s/g, "-"); // replace spaces with dashes in filename
    const response = await dbSDK.filesUpload({path: `/${applicantEmail}/${workSampleType}/${fileName}`, contents: file});
    // use drpbx response to format url that directs to correct dropbox folder
    fields[workSampleType] = `https://www.dropbox.com/home/Apps/${dropbox_folder}${response.path_display}`;
    uploadLinkToAirtable(applicant, fields, type, setUploadError);
  } catch (err) {
    setUploadError('Sorry, there was an error uploading your files.');
    return;
  }
}

// update user record with link to dropbox folder for upload
export const uploadLinkToAirtable = (applicant, fields, type, setUploadError) => {

  // connect to the correct airtable base
  const base = new Airtable({apiKey: process.env.GATSBY_AIRTABLE_API}).base(baseTypeMap[type]);

  // update the base with new fields 
  base(type).update([
    {
      "id": applicant,
      "fields": fields,
    }
  ],
    function(err, records) {
      if (err) {
        setUploadError('Sorry, there was an error uploading your files.');
        return;
      }
    });
}
