import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import { useQueryParam, StringParam } from "use-query-params";
import FileUpload from "../components/fileUpload";
import LoadingSpinner from '../components/loadingSpinner';
import '../styles/upload.scss';
import Airtable from 'airtable';

const baseTypeMap = {
  visual: process.env.GATSBY_AIRTABLE_VISUAL,
  music: process.env.GATSBY_AIRTABLE_MUSIC,
  technology: process.env.GATSBY_AIRTABLE_TECH,
}


const ApplicationPage = (props) => {
  // applicant airtable record id
  const [applicant, setApplicant] = useQueryParam("applicant", StringParam);
  const [applicantEmail, setApplicantEmail] = useState(null);
  // application type corresponds to the base (tech, visual, music ect..)
  const [type, setType] = useQueryParam("type", StringParam);

  // connect to the correct airtable base
  const base = new Airtable({apiKey: process.env.GATSBY_AIRTABLE_API}).base(baseTypeMap[type]);

  useEffect(() => {
   // call airtable api to get applicant's email
   if (applicant) {
    base(type).find(applicant, function(err, record) {
      if (err) { 
        // if there was an error fall back to applicant's record id
        setApplicantEmail(applicant);
      } else {
        setApplicantEmail(record.fields.Email)
      }
    });
  }
  }, [applicant]);

  // corresponds to work sample type
  const [uploadType, setUploadType] = React.useState();

  // submission status
  const [ isSubmitted, setIsSubmitted ] = React.useState(false);
  const [ isLoading , setIsLoading ] = React.useState(false);
  const [ uploadError, setUploadError ] = React.useState(null);

  return (
    <Layout>
      <div className="upload-type-container">
        <div className="instructions center">
          { uploadError && (<h2 className="upload-error">{uploadError}</h2>)}
          { !uploadError && ( <>
            {isLoading
              ? (
              <div>
                  <LoadingSpinner className="loading-spinner"/>
                  <h2>This may take a few seconds, please do not refresh the browser.</h2>
              </div>
              )
              : <h2>
                  {isSubmitted
                    ? `Thanks for your submission to the ${type} residency!`
                    : `To complete your ${type} residency application, please select and submit your work samples in one of the following formats:`
                  }
                </h2>
            }
          </>
          )}
        </div>
        {!isSubmitted && !isLoading && !uploadError && (
          <form className="upload-type">
            <select value={uploadType} onChange={e => setUploadType(e.currentTarget.value)}>
                <option className="opt" selected disabled hidden value="">Choose here &#8595;</option>
                <option className="opt" value="digital-images">Digital Images</option>
                <option className="opt" value="video-audio">Video/Audio</option>
                <option className="opt" value="combination">Combination</option>
              </select>
          </form>
        )}
        </div>
          {!isSubmitted && !isLoading && uploadType && uploadType !== 'click to select..' && (
            <div className="file-upload-container">
              <FileUpload 
                type={type} 
                applicant={applicant} 
                setIsSubmitted={setIsSubmitted} 
                uploadType={uploadType}
                setIsLoading={setIsLoading}
                setUploadError={setUploadError}
                applicantEmail={applicantEmail}
              />
            </div> 
          )}
    </Layout>
)}

export default ApplicationPage;