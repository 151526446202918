import PropTypes from "prop-types";
import React from "react";
import '../styles/header.scss';

const Header = ({ siteTitle }) => (
  <header>
    <div className="header-container">
      <div className="header-title">
          <a href="https://www.pioneerworks.org">
            {siteTitle}
          </a>
      </div>
      <div className="header-title">
        Residency
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
