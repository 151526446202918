import React, { useEffect, useRef } from "react"
import Dropbox from 'dropbox';
import { Formik} from "formik";
import { useDropzone } from "react-dropzone";
import { uploadTypeMap } from '../constants';
import '../styles/fileUpload.scss';
import { uploadToDropbox } from '../helpers/dropboxUpload';


// drag and drop component
const UploadComponent = props => {
  const { setFieldValue, name, fileType, instructions, setFormError } = props;
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: fileType,
    onDrop: acceptedFiles => { 
      setFormError(null);
      setFieldValue(name, acceptedFiles);
    }
  });
  return (
    <div className="upload-component">
      <div {...getRootProps({ className: "dropzone" })}>
        {!isDragActive && (<h1>{instructions}</h1>)}
        <input {...getInputProps()} />
        {isDragActive && (<h1>+</h1>)}
      </div>
    </div>
  );
};

const FileUpload = ({ uploadType, setIsSubmitted, type, setIsLoading, setUploadError, applicantEmail, applicant }) => {

  const formikRef = useRef(null);

  useEffect(() => {
    // when new upload type is selected reset the form errors and form values
    setFormError({});
    if (formikRef.current) {
      formikRef.current.setFieldValue('input', null);
      formikRef.current.setFieldValue('input2', null);
      formikRef.current.setFieldValue('video-audio', null);
      formikRef.current.setFieldValue('images', null);
      formikRef.current.setFieldValue('uploadType', uploadType);
    }
  }, [uploadType]);

  // get the validation specs for the upload type
  const uploadSpec = uploadTypeMap[uploadType];
  const {fileUploads, input = null, input2 = null } = uploadSpec;
  const [ wordCount, setWordCount ] = React.useState(0);
  const [ wordCountInput2, setWordCountInput2 ] = React.useState(0);
  const [ formError, setFormError ] = React.useState(null);

  const onTextAreaChange = (e, setFieldValue, input) => {
    const textinput = e.target.value;
    setWordCount(textinput.split(' ').length);
    setFieldValue(input, textinput);
    setFormError(null);
  }

  const submitForm = async (values) => {
    let errors = {}
    setFormError(null);

    fileUploads.map(uploads => {
      if (values[uploads.name] === null) {
        errors[uploads.name] = `${uploads.name} cannot be blank`;
      }
      if (values[uploads.name] && values[uploads.name][0].size >= uploads.sizeValidation) {
        errors[uploads.name] = `${uploads.name} file too large. Max ${uploads.sizeValidation/1000000}MB`
      }
    });

    if (input){
      if (values.input === null || !(values.input && values.input.length > 1) ) {
        errors.input = `Description cannot be blank`;
      }  else if (wordCount > 110) {
        errors.input = `Description word count limit exceeded. Max 110 words.`;
      }
    }

    if (input2){
      if (values.input2 === null || !(values.input2 && values.input2.length > 1) ) {
        errors.input2 = `Description cannot be blank`;
      }  else if (wordCount > 250) {
        errors.input2 = `Description word count limit exceeded. Max 250 words.`;
      }
    }

    if (Object.keys(errors).length !== 0) {
      return setFormError(errors);
    }
  
    if (formError) {
      return;
    } else {
      setIsLoading(true);
    }
    const cvFile = values['CV'] ? values['CV'][0] : null
    const videoFile = values['video-audio'] ? values['video-audio'][0] : null
    const imageFile = values['images'] ? values['images'][0] : null
    const fields = { 
      "input": values.input, 
      "Work Sample Format": values.uploadType,
      "How would you use your time at Pioneer Works (250 word max)?": values.input2,
    };
    const dbx = new Dropbox.Dropbox({ 
      fetch,
      accessToken: process.env.GATSBY_DROPBOX_KEY,
    });

    // upload to dropbox according to upload type
    switch (uploadType) {
      case 'combination':
        await uploadToDropbox(dbx, applicant, 'video-audio', videoFile, fields, type, setUploadError, applicantEmail);
        await uploadToDropbox(dbx, applicant, 'CV', cvFile, fields, type, setUploadError, applicantEmail);
        await uploadToDropbox(dbx, applicant, 'images', imageFile, fields, type, setUploadError, applicantEmail);
        break;
      case 'digital-images':
        await  uploadToDropbox(dbx, applicant, 'images', imageFile, fields, type, setUploadError, applicantEmail);
        await uploadToDropbox(dbx, applicant, 'CV', cvFile, fields, type, setUploadError, applicantEmail);
        break;
      case 'video-audio':
        await uploadToDropbox(dbx, applicant, 'video-audio', videoFile, fields, type, setUploadError, applicantEmail);
        await uploadToDropbox(dbx, applicant, 'CV', cvFile, fields, type, setUploadError, applicantEmail);
        break;
    }
    
    // set form status
    setIsLoading(false);
    setIsSubmitted(true);
  }

  return (
    <div className="file-upload-component">
      <Formik
      innerRef={formikRef}
      initialValues={{ 'images': null, 'video-audio':null, 'input': null }}
      onSubmit={(values) => submitForm(values)}
      >

      {({ values, handleSubmit, setFieldValue }) => {
        return (
          <form className="col-start" onSubmit={(e) => {handleSubmit(e)}}>
            <div className="dropzones-container">

              {input2 && (
                <div className="textarea-wrapper">
                <textarea
                  value={values.input2 ? values.input2: null}
                  placeholder={input2.instructions} 
                  name="input2"
                  onChange={(e) => onTextAreaChange(e, setFieldValue, 'input2')}
                />
                  <div className='form-error'>{formError && formError.input2 && (formError.input2)}</div>
                </div>
              )}
              {input && (
                <div className="textarea-wrapper">
                <textarea
                  value={values.input ? values.input: null}
                  placeholder={input.instructions} 
                  name="input"
                  onChange={(e) => onTextAreaChange(e, setFieldValue, 'input')}
                />
                  <div className='form-error'>{formError && formError.input && (formError.input)}</div>
                </div>
              )}

              {fileUploads.map(spec => {
                // set the instructions for the file uploads according to the spec
                const instructions = values[spec.name] && values[spec.name][0] && values[spec.name][0].name ? values[spec.name][0].name : spec.instructions
                return (
                  <div key={spec.name}>
                    <div className="form-group start-col dropzone-container">
                      <UploadComponent 
                        setFieldValue={setFieldValue}
                        name={spec.name} 
                        fileType={spec.fileTypeValidation} 
                        instructions={instructions} 
                        setFormError={setFormError}
                      />
                    </div>
                    <div className='form-error'>{formError && formError[spec.name] && (formError[spec.name])}</div>
                  </div>
                )}
              )}

            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        );
      }}
      </Formik>
    </div>
  );
}

export default FileUpload;