const uploadTypeMap = {
  'digital-images': {
    fileUploads: [
      {
        name: 'images',
        instructions: 'Click or drag files here to upload 10 still images and caption list in single pdf. Please keep PDF file under 20MB.',
        sizeValidation: 20000000, // 10MB
        fileTypeValidation: 'application/pdf',
      },
      {
        name: 'CV',
        instructions: 'Click or drag files here to upload your CV as a PDF. (3 pages max.)',
        sizeValidation: 10000000, // 10MB
        fileTypeValidation: 'application/pdf',
      },
    ],
    input2: {
      instructions: "How would you use your time at Pioneer Works? (250 word max)",
      lengthValidation: 250 //words
    }
  },
  'video-audio': {
    fileUploads: [
      {
        name: 'video-audio',
        instructions: 'Click or drag files here to upload 1 video or audio clip up to 5 minutes in length. Please keep file under 50MB.',
        sizeValidation: 50000000,
        fileTypeValidation: 'video/*,audio/*',
        timeValidation: 5 * 60,
      },
      {
        name: 'CV',
        instructions: 'Click or drag files here to upload Please upload your CV as a PDF. (3 pages max.)',
        sizeValidation: 10000000, // 10MB
        fileTypeValidation: 'application/pdf',
      },
    ],
    input: {
      instructions: 'Please include short description of your video/audio work (110 word max)',
      lengthValidation: 110 //words
    },
    input2: {
      instructions: "How would you use your time at Pioneer Works? (250 word max)",
      lengthValidation: 250 //words
    }
  },
  'combination': {
    fileUploads: [
      {
        name: 'video-audio',
        instructions: 'Click or drag files here to upload 1 video or audio clip up to 2 minutes in length. Please keep file under 50MB.',
        fileTypeValidation: 'video/*,audio/*',
        timeValidation: 2 * 60,
        sizeValidation: 50000000, //MB
      },
      {
        name: 'images',
        instructions: 'Click or drag files here to upload 5 still images and caption list in single pdf. Please keep PDF file under 10MB.',
        sizeValidation: 10000000, // 10MB
        fileTypeValidation: 'application/pdf',
      },
      {
        name: 'CV',
        instructions: 'Click or drag files here to upload Please upload your CV as a PDF. (3 pages max.)',
        sizeValidation: 10000000, // 10MB
        fileTypeValidation: 'application/pdf',
      },
    ],
    input: {
      instructions: 'Please include short description of your video/audio work (110 word max)',
      lengthValidation: 110 //words
    },
    input2: {
      instructions: "How would you use your time at Pioneer Works? (250 word max)",
      lengthValidation: 250 //words
    }
  }
};

export { uploadTypeMap };